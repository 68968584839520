import React from 'react'
import heroimage from '../assets/whQ.gif'
import { TypeAnimation } from "react-type-animation"



const Hero = () => {
  return (
    <div className='grid grid-cols-1 sm:grid-cols-3 gap-0 md:max-w-[1200px] md:mx-auto py-8'>
      
       <div className='my-auto m-auto w-[400px] h-auto lg:w-[400px]'>
            <img src={heroimage} alt="hero image"/>
        </div>


        <div className='col-span-2 px-5 my-auto'>

        <h1 className="text-white text-4x1 sm:text-5xl lg:text-8xl font-extrabold">
            <span className="primary-color">
               Gorky Records
            </span> <br/>
            <TypeAnimation
            sequence={[
                "Дистрибьюция",
                1000,
                "Продвижение",
                1000,
                "Поддержка",
                1000,
            ]}
            wrapper="span"
            speed={50}
            repeat={Infinity}
            />
            </h1>

            <p className="text-white sm:text-lg my-6 lg:text-xl">
                Мы предлагаем музыкальное издательство по всему миру!
            </p>
            <div className='my-8'>
                <a href="https://music.yandex.ru/label/702898" className="px-6 py-3 w-full rounded-xl mr-4
                        bg-gradient-to-br from-orange-500 to-pink-500 text-white">
                            Наши артисты
                        </a>
                        <a href="#contact" className="px-6 py-3 w-full rounded-xl
                                border border-gray-400 hover:bg-gradient-to-br from-orange-500 to-pink-500
                                text-white hover:border-none">
                            Связаться
                        </a>            
                    </div>
                </div>
    </div>
  )
}

export default Hero
