import React from 'react';
import { FaVk, FaTelegramPlane, FaYandex } from 'react-icons/fa'; // Импорт иконок для ВК, Telegram и Яндекс

const Footer = () => {
  return (
    <div className="max-w-[1200px] sm:h-[150px] p-11 flex justify-between items-center mx-auto">
        <span className='primary-color'>Gorky Records</span>
        <p className='text-gray-600'>gorkyrecords@gmail.com <br/> +7 (968) 556-67-18 </p>
        <div className="flex"> {/* Добавлен класс flex для горизонтального расположения иконок */}
            <a href="https://vk.com/gorky_records?ysclid=ltbjvfc1rb490935752" target="_blank" rel="noopener noreferrer" className='text-gray-600 mx-2'>
                <FaVk size={24} />
            </a>
            <a href="https://music.yandex.ru/label/702898" target="_blank" rel="noopener noreferrer" className='text-gray-600 mx-2'>
                <FaYandex size={24} />
            </a>

        </div>
    </div>
  )
}

export default Footer
