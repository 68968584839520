import React, {useState} from 'react'
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'



const Navbar = () => {
    const [nav, setNav] = useState(false)
    
    const handleNav = () => {
        setNav(!nav)
    }
    
    // Функция для закрытия меню
    const closeNav = () => {
        setNav(false)
    }

  return (
    <div>
      <div className='bg-black text-gray-400 h-[100px] h-[100px] max-w-[1200px] mx-auto flex justify-between items-center'>

        <h1 className='text-3xl font-bold primary-color ml-4'>Gorky Records</h1>
        <ul className='hidden md:flex'>
            <li className='p-5'><a href='#about' onClick={closeNav}>О нас</a></li>
            <li className='p-5'><a href='#work' onClick={closeNav}>Кейсы</a></li>
            <li className='p-5'><a href='#contact' onClick={closeNav}>Связаться</a></li>
        </ul>

        <div onClick={handleNav} className='block md:hidden mr-8'>
            {nav ? <AiOutlineClose size={40}/> : <AiOutlineMenu size={40}/>}
            </div> 

            <div className={nav ? 'fixed h-full left-0 top-0 w-[60%] bg-[#202121] ease-in-out duration-800'
            : 'fixed left-[-100%]'}>
                <h1 className='text-3xl primary-color m-4'>Gorky Records</h1>
                <ul className='p-8 text-2xl'>
                    <li className='p-2'><a href='#about' onClick={closeNav}>О нас</a></li>
                    <li className='p-2'><a href='#work' onClick={closeNav}>Кейсы</a></li>
                    <li className='p-2'><a href='#contact' onClick={closeNav}>Связаться</a></li>
                </ul>
            </div>

      </div>
    </div>
  )
}

export default Navbar
