import React from 'react'
import aboutImg from '../assets/about.jpg'

const About = () => {
  return (
    <div className="text-white max-w-[1200px] mx-auto my-12" id="about">
      
      <div className="md:grid md:grid-cols-2 sm:py-16">

        <div className="mt-4 md:mt-0 text-left flex flex-col h-full justify-start py-6 md:py-0">
            <div className="my-auto mx-6">
                <h2 className="text-4xl font-bold mb-4 primary-color">О нас</h2>
                <p className="text-base lg:text-lg">
                Gorky Records — это не просто музыкальный лейбл, это целый мир, где каждая нота, каждый звук и каждое слово в песне несут особенный смысл. Мы — коллектив единомышленников и профессионалов, влюбленных в музыку и стремящихся изменить мир к лучшему через магию звуков. <br/>
                <br/> Присоединяйтесь к нам в этом захватывающем путешествии, исследуйте новые горизонты вместе с Gorky Records и станьте частью большого музыкального движения, меняющего мир к лучшему!
                </p>
            </div>
        </div>
        <img className="mx-auto rounded-3xl py-8 md:py-0" src={aboutImg} alt="Описание изображения" width={500} height={800} />
      </div>

    </div>
  )
}

export default About
