import React, { useState, useEffect } from 'react';
import html from '../assets/html.png';
import css from '../assets/css.png';
import javascript from '../assets/javascript.png';
import tailwind from '../assets/tailwind.png';
import react from '../assets/react.png';

const Skills = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Стиль для изображений, адаптированный под мобильные устройства
  const imageStyle = {
    width: isMobile ? '80px' : '100px',
    height: isMobile ? '80px' : '100px',
    objectFit: 'cover',
  };

  // Стиль для элементов, содержащих изображения, адаптированный под мобильные устройства
  const itemStyle = {
    margin: isMobile ? '2px' : '5px', 
  };

  return (
    <div className='bg-black text-gray-400 max-w-[1200px] mx-auto overflow-hidden'>
      <h2 className="text-gray-700 text-xl md:text-4xl font-bold m-4">Партнёры</h2>
      <div className="partner-scroll">
        <div className="partner-container">
          {[...Array(2)].map((_, i) => (
            <React.Fragment key={i}>
              <div className="partner-item" style={itemStyle}>
                <img src={html} alt="HTML" style={imageStyle} />
                <p className="mt-2"></p>
              </div>
              <div className="partner-item" style={itemStyle}>
                <img src={css} alt="CSS" style={imageStyle} />
                <p className="mt-2"></p>
              </div>
              <div className="partner-item" style={itemStyle}>
                <img src={javascript} alt="JavaScript" style={imageStyle} />
                <p className="mt-2"></p>
              </div>
              <div className="partner-item" style={itemStyle}>
                <img src={tailwind} alt="Tailwind" style={imageStyle} />
                <p className="mt-2"></p>
              </div>
              <div className="partner-item" style={itemStyle}>
                <img src={react} alt="React" style={imageStyle} />
                <p className="mt-2"></p>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Skills;
