import React from 'react';
import { init, sendForm } from '@emailjs/browser';

// Инициализируйте EmailJS с вашим user ID
init("mH9CuighDvZFZnvOh");

const Contact = () => {
  const sendEmail = (e) => {
    e.preventDefault();

    sendForm('service_4ze1a8a', 'template_ov5flrn', e.target)
      .then((result) => {
          alert('Заявка успешна отправлена!');
      }, (error) => {
          alert('Ошибка при отправке заявки: ', error.text);
      });
  };

  return (
    <div className="max-w-[1200px] mx-auto bg-black sm:py-20 p-5" id="contact">
        <div className="text-center">
            <h2 className="text-4xl font-bold leading-tight primary-color">Отправить трек на прослушивание</h2>
        </div>
        <div className="max-w-[800px] mx-auto">
            <div className="mt-6 bg-[#161616] rounded-xl">
                <div className="p-10">
                <form onSubmit={sendEmail}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-4">
                        <div>
                            <div className="mt-2.5 relative">
                                <input type="text" name="name" placeholder="Ваше имя"
                                className="bg-[#161616] w-full px-4 py-4 text-gray-400 placeholder-gray-400 border
                                border-gray-700 rounded-md focus:outline-none focus:border-pink-600" />
                            </div>
                        </div>
                        <div>
                            <div className="mt-2.5 relative">
                                <input type="email" name="email" placeholder="Ваша почта"
                                className="bg-[#161616] w-full px-4 py-4 text-gray-400 placeholder-gray-400 border 
                                border-gray-700 rounded-md focus:outline-none focus:border-pink-600" />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <div className="mt-2.5">
                                <textarea name="message" placeholder="Ваше сообщение (ссылка на трек)"
                                 className="bg-[#161616] w-full px-4 py-4 text-gray-400 placeholder-gray-400 border
                                 border-gray-700 rounded-md focus:outline-none focus:border-pink-600"></textarea>
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <button type="submit"
                            className="text-xl w-full p-4 mt-2 font-semibold text-white bg-primary-color rounded-md">
                                Отправить
                            </button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Contact;
